import React, { useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom'

const PayPal = ({pedido, totals, reloadPaypal, setReloadPaypal}) => {

	const navigate = useNavigate();

	useEffect(()=>{
		setReloadPaypal(false)
	},[reloadPaypal])

	const _onApprove = (data, actions) => {
		return actions.order.capture().then((details) =>{
			navigate('/micarrito/finaliza')
		});
	}

	const _createOrder = (data, actions) => {

		const order = pedido.envio[0].servicio==='Recolección'?
			{
				reference_id:`Asiamart_Pedido-${pedido.id}`,
				invoice_id:`Asiamart_Pedido-${pedido.id}`,
				custom_id:`Asiamart_Pedido-${pedido.id}`,
				amount: {
					value: totals.total,
				},
			}:
			{
				reference_id:`Asiamart_Pedido-${pedido.id}`,
				invoice_id:`Asiamart_Pedido-${pedido.id}`,
				custom_id:`Asiamart_Pedido-${pedido.id}`,
				shipping:{
					name:{
						full_name:pedido.envio[0].nombre
					},
					address: {
						address_line_1: `${pedido.envio[0].calle} ${pedido.envio[0].numExt} ${pedido.envio[0].numInt?'Int:'+pedido.envio[0].numInt:''}`,
						admin_area_2: pedido.envio[0].ciudad?pedido.envio[0].ciudad:'ciudad no disponible',
						postal_code: pedido.envio[0].cp,
						country_code: 'MX'
					}
				},
				amount: {
					value: totals.total,
				},
			}
		return actions.order.create({
			purchase_units: [order],
		});
	}
	/*
	   * develop client-id : AQTDDLgpQ2n7GOt5o3e-BvshsjCoLQvOctwsJHA2hseJPbeXYt_ouFXpNrEc0G0M-JtlwoaWwV3ZcerH
	   * develop2 client-id : AQdpuDrGI9087-cUKXHHmmB96Q-MziDVKOQAmPPFk6dXemBuuyP1MwfeC8OKMK1EPzQlF0TsFrwmwWMH
	   * production client-id : ATl-P3TJTc4GTc8rE7R-6pFPujY-q9XVxFTn0OTnMFhh_PMnS2bYrhQ0nSyet2mJbGT7QJ6FCpAhJhji
	   *
	   */

	if(!reloadPaypal)
	return (
		<PayPalScriptProvider options={{ "client-id": "ATl-P3TJTc4GTc8rE7R-6pFPujY-q9XVxFTn0OTnMFhh_PMnS2bYrhQ0nSyet2mJbGT7QJ6FCpAhJhji" ,components: "buttons", currency: "USD", locale:'es_MX'}}>
			<PayPalButtons onApprove = {_onApprove} createOrder={_createOrder} />
		</PayPalScriptProvider>
	)

	return (
		<div>reloadPaypal...</div>
	)
}

export default PayPal
