import React, { useEffect, useState } from 'react';
import { axios } from '../../../infrastructure/axios';
import { useNavigate } from 'react-router-dom';
import M from 'materialize-css'

const StepOne = () => {

	let navigate = useNavigate();
	const [config,setConfig] = useState({nombretienda:'',telefonoatencion:'',emailserviciocliente:'',nombre_seccion_principal:'',img:'',logo:'',navbackground:'', navtext:'', primarycolor:'', secondarycolor:'',infocolor:'',hovercolor:'',footerbackground:'',footertext:'',configs:{horario:{}}});
	const dias = ['Domingo','Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado']
	const [loader, setLoader] = useState(false)

	useEffect(() => {
		axios.get('/api/reseller/configwebsite')
		.then((r) => {
			setConfig(r.data)
			M.updateTextFields()
			M.FormSelect.init(document.querySelectorAll('select'), {});
		})
		.catch(r=>alert(r))
	},[])

	useEffect(() => {
		console.log(config)
	},[config])

	const _saveData = (e) =>{

		e.preventDefault();
		const {nombretienda,telefonoatencion,emailserviciocliente,nombre_seccion_principal,img,navbackground, navtext, primarycolor, secondarycolor,infocolor,hovercolor,footerbackground,footertext,configs} = {...config}
		const conf = {nombretienda,telefonoatencion,emailserviciocliente,nombre_seccion_principal,img,navbackground, navtext, primarycolor, secondarycolor,infocolor,hovercolor,footerbackground,footertext,configs}

		if(!conf.navbackground)return alert("Por favor completa el campo color barra de navegación.")
		if(!conf.navtext)return alert("Por favor completa el campo color texto barra de navegación.")
		if(!conf.primarycolor)return alert("Por favor completa el campo color principal.")
		if(!conf.secondarycolor)return alert("Por favor completa el campo color secundario")
		if(!conf.infocolor)return alert("Por favor completa el campo de color para textos informativos")
		if(!conf.hovercolor)return alert("Por favor completa el campo color de resaltado")
		if(!conf.footerbackground)return alert("Por favor completa de fondo del pie de página")
		if(!conf.footertext)return alert("Por favor completa el campo texto del pie de página")
			
		const data = new FormData();
		data.append('_method', 'POST')
		data.append('config', JSON.stringify(conf))
		data.append('img', conf.img)
		
		setLoader(true)
		axios.post('/api/reseller/setconfigsgenerales', data)
		.then((r) => {
			if(r.status === 200){
				axios.put('/api/reseller/savestatus',{config_store:{"wizard_config":{"installed":0,"status":"step_two"}}}).then((res)=>{
					M.toast({html:'Datos Actualizados!'})
					navigate("/admin");
					window.location.reload();
				}).catch(e=>console.log("error",e))
				setLoader(false)
			}
		})
		.catch(r=>alert(r))
	   
	}

	return (
		<div className='padding-20' style={{position:'relative',minHeight:600}}>
			<h5>Configuraciones de tu tienda online</h5>
			<h6>Todo sus cambios o ajustes se verán reflejados al presionar el botón de SIGUIENTE en la parte inferior</h6> 
			<h6 style={{marginTop:15}}>Datos de tu e-commerce</h6>
			<form onSubmit={(e)=>_saveData(e)}>
				<div className="row">
					<div className="input-field col s6">
						<label htmlFor="nombre_tienda">Nombre de tu tienda</label>
						<input style={{background:'white',border:'1px solid #ebebeb'}} id="nombre_tienda" type="text" name="nombre_tienda" value={config.nombretienda} onChange={(e) => setConfig({...config,nombretienda:e.target.value})} className="validate"  required />
					</div>
					<div className="input-field col s6">
						<label htmlFor="tel_tienda">Teléfono de tu tienda</label>
						<input style={{background:'white',border:'1px solid #ebebeb'}}id="tel_tienda" type="text" name="tel_tienda" value={config.telefonoatencion} onChange={(e) => setConfig({...config,telefonoatencion:e.target.value})} className="validate" required />
					</div>
					<div className="input-field col s6">
						<label htmlFor="email_tienda">Email de servicio al cliente</label>
						<input style={{background:'white',border:'1px solid #ebebeb'}} id="email_tienda" type="text" name="email_tienda" value={config.emailserviciocliente} onChange={(e) => setConfig({...config,emailserviciocliente:e.target.value})} className="validate" required />
					</div>
					<div className="input-field col s6">
						<label htmlFor="nombre_seccionprincioal">Nombre del menú principal(Ej. Departamentos)</label>
						<input style={{background:'white',border:'1px solid #ebebeb'}} id="nombre_seccionprincioal" type="text" name="nombre_seccionprincioal" value={config.nombre_seccion_principal} onChange={(e) => setConfig({...config,nombre_seccion_principal:e.target.value.length<=20?e.target.value:config.nombre_seccion_principal})} className="validate" required />
						<div style={{color:'black', float:'right'}}>{config.nombre_seccion_principal?.length}/20</div>
						<div style={{color:'red', fontSize:12}}>Nota: el nombre del menú principal aparece en la barra de navegación principal</div>
					</div>
				</div>

				<h6 style={{fontWeight:'700',}}>Horarios de atención</h6>
				<div className='row'>
					<div className='col s12 padding-20'>
						<div className='card cero'>
							<div className="input-field col s12 m2 p-2">
								<select className="los-input" defaultValue='' value={config.configs.horario.diaInicio} onChange={(e)=>setConfig({...config, configs:{...config.configs,horario:{...config.configs.horario,diaInicio:e.target.value}}})}>
									<option value="">Selecciona</option>
									{dias.map((d,i) => <option key={i} value={d}>{d}</option>)}
								</select>
								<label>Día Inicio</label>
							</div>
							<div className="input-field col s12 m2 p-2">
								<select  className="los-input" style={{backgroundColor:'#ffffff!important'}} defaultValue='' value={config.configs.horario.diaFin} onChange={(e)=>setConfig({...config, configs:{...config.configs,horario:{...config.configs.horario,diaFin:e.target.value}}})}>
									<option className="los-input" value=""  style={{backgroundColor:'#ffffff!important'}}>Selecciona</option>
									{dias.map((d,i) => <option className="los-input" key={i} value={d} style={{backgroundColor:'#ffffff!important'}}>{d}</option>)}
								</select>
								<label>Día Fin</label>
							</div>
				
							<div className="input-field col s12 m3 p-2">
								<input id="hora_inicio" value={config.configs.horario.horaInicio||''} name="dia_inicio" onChange={(e)=>setConfig({...config, configs:{...config.configs,horario:{...config.configs.horario,horaInicio:e.target.value}}})} type="time" className="validate los-input" required />
								<label htmlFor="hora_inicio">Hora inicio</label>
							</div>
							<div className="input-field col s12 m3 p-2">
								<input id="hora_fin" name="hora_fin" value={config.configs.horario.horaFin||''} onChange={(e)=>setConfig({...config, configs:{...config.configs,horario:{...config.configs.horario,horaFin:e.target.value}}})} type="time" className="validate los-input" required />
								<label htmlFor="hora_fin">Hora fin</label>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<h6 style={{fontWeight:700}}>Colores</h6>
					<div className="col">
						<div>Barra de navegación</div>
						<input type="color" className="validate" value={config.navbackground} onChange={(e)=>setConfig({...config,navbackground:e.target.value})} style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}} required/>
					</div>
					<div className="col">
						<div>Color texto barra de navegación</div>
						<input value={config.navtext} onChange={(e)=>setConfig({...config,navtext:e.target.value})} type="color" style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}} required/>
					</div>
					<div className="col">
						<div>Color principal</div>
						<input type="color" className="validate" value={config.primarycolor} onChange={(e)=>setConfig({...config, primarycolor:e.target.value})} style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}} required/>
					</div>
					<div className="col">
						<div>Color secundario</div>
						<input type="color" value={config.secondarycolor} onChange={(e)=>setConfig({...config,secondarycolor:e.target.value})} style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}} required/>
					</div>
					<div className="col">
						<div>Color para textos informativos</div>
						<input type="color" value={config.infocolor} onChange={(e)=>setConfig({...config,infocolor:e.target.value})} style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}} required/>
					</div>
					<div className="col">
						<div>Color de resaltado</div>
						<input  type="color" value={config.hovercolor} onChange={(e)=>setConfig({...config,hovercolor:e.target.value})} style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}} required/>
					</div>
					<div className="col">
						<div>Color de fondo del pie de página</div>
						<input  type="color" value={config.footerbackground} onChange={(e)=>setConfig({...config,footerbackground:e.target.value})} style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}} required/>
					</div>
					<div className="col">
						<div> Color del texto del pie de página</div>
						<input  type="color" value={config.footertext} onChange={(e)=>setConfig({...config,footertext:e.target.value})}  style={{width:200, height:40, padding:0,border:0, boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)'}} required/>
					</div>
				</div>

				<div className="row">
					<h6 style={{fontWeight:700}}>Logo de tu tienda</h6>
					<div className='col s12 m4'>
						<div>Sube el logo de tu tienda</div>
						<p style={{color:'red', fontSize:'12px'}}>Nota: Para una mejor experiencia utiliza una imagen en formato PNG sin fondo</p>
						<label style={{cursor:'pointer',}}>
							<div style={{height:'200px', width:'200px'}}>
								<img alt='img' src={config.img?URL.createObjectURL(config.img):config.logo?config.logo:'https://storage-apis.s3.us-east-2.amazonaws.com/kangoru-shop-api/config/subirImg.jpg'}
								style={{margin:'0 auto', maxHeight:'100%', maxWidth:'100%', objectFit:'contain',textAlign:'left'}} />
							</div>
							<input  onChange={(e)=>setConfig({...config,img:e.target.files[0]})}  type="file" style={{opacity:0}} accept="image/*"/>
						</label>
					</div>
				</div>
			
				{loader===true?
				<div className="progress">
					<div className="indeterminate"></div>
				</div>:
				<button className='btn' style={{backgroundColor:'#4966B1',color:'white',position:'absolute',right:20,bottom:20,height:50,width:150}}>Siguiente</button>
				}
			</form>
		</div>
	);
};

export default StepOne;
