import React, { useEffect, useState, useContext } from 'react';
import './pedidos.css'
import M from 'materialize-css'
import axios from '../../../../infrastructure/axios';
import { Link } from 'react-router-dom'
import { DataContext } from '../../../../contexts/DataContext'
import Loader from '../../../../components/Loader';

const Pedidos = () => {

	const { carrito, setCarrito, user, setUser } = useContext(DataContext)
	const [pedidos, setPedidos] = useState([])
	const [cotizaciones, setCotizaciones] = useState([])
	const [loader,setLoader] = useState(true)

	useEffect(() =>{

		const fetchData = async () => {
			try {
				// Fetch Cotizaciones
				const cotizacionesResponse = await axios.get('/api/mis_cotizaciones');
				setCotizaciones(cotizacionesResponse.data);
				console.log('cotizaciones')

				// Fetch Pedidos
				const pedidosResponse = await axios.get('/api/mispedidos');
				setPedidos(pedidosResponse.data);
				console.log('pedidos')

				setLoader(false)

				// Desplaza el scroll de los chats del tab QUOTES (conversaciones de cotizaciones) hasta abajo
				setTimeout(()=>{
					const containers = document.querySelectorAll("#chat");
					console.log(containers)
					containers.forEach(container => {
						container.scrollTop = container.scrollHeight;
					});
					M.Tabs.init(document.querySelectorAll('.tabs'),{})
				}, 2000)

			}catch(error){
				alert(error)
			}
		}

		fetchData()

	},[])

	const _recomprar = (cantidad, codigo) => {
		axios.get(`/api/producto/${codigo}`)
		.then(r=>{
			if(r.data.stock >= cantidad){
				const obj = carrito.find((c)=>c.codigo===codigo)
				const newCantidad =  typeof(obj) === 'undefined' ? cantidad:obj.cantidad+cantidad
				setCarrito([...carrito.filter(c=>!c.codigo.includes(codigo)), {...r.data, cantidad:newCantidad}])
				M.toast({html: 'Carrito Actualizado!'})
			}
			else{
				alert("No contamos con ese número de productos en stock")
			}
		})
		.catch(r=>alert(r))
	}

	const _cotizacion_mensaje = (e, cotizacion) => {
		e.preventDefault();

		const conversation_uuid = cotizacion.conversation_uuid
		const mensaje = e.target.elements.mensaje.value

		const formData = new FormData();

		formData.append('conversation_uuid', conversation_uuid)
		formData.append('mensaje', mensaje)
		 // Agrega los archivos al FormData
		if(cotizacion.dataForm?.files.length>0)
		for (let i = 0; i < cotizacion.dataForm.files.length; i++) {
			formData.append('files[]', cotizacion.dataForm.files[i]);
		}

		axios.post('/api/message/conversation_mensaje', formData)
		.then(r=>{
			setCotizaciones(cotizaciones.map(c=>c.conversation_uuid===conversation_uuid?{...c, conversation: {...c.conversation, messages: [...c.conversation.messages, r.data]}, dataForm:{files:[]}}:c))
			e.target.elements.mensaje.value=''

			const containers = document.querySelectorAll("#chat");
			containers.forEach(container => {
				container.scrollTop = container.scrollHeight;
			});



		})
		.catch(r=>alert(r))
		
	}

	const _visto = (cotizacion) => {
		console.log(cotizacion)
		axios.post('/api/cotizacion_view', cotizacion)
		.then(r=>{
			setCotizaciones(cotizaciones.map(c=>c.conversation_uuid===cotizacion.conversation_uuid?{...c, user_view: true}:c))
			setUser({...user, notifications: {...user.notifications, cotizaciones: user.notifications.cotizaciones-1}})
		})
		.catch(r=>alert(r))
	}

		return (
			<div style={{minHeight: '80vh'}}>
				<div className='row m-p-0'>
					<div className='col s12 m-p-0 barra'>
						<div>My shopping: </div>
					</div> 
				</div>
				{loader ? <Loader />:
				
				<div className="cont-result bg-container">
					<div className="container-medium margin-top-50">
						<div className='row'>
							<div className='col s12 m-p-0'>
								<div className='col s12 m-p-0'>
									<ul id="tabs-swipe-demo" className="tabs ul-tabs">
										<li className="tab col s2"><a className="active" href="#test-swipe-1">Ongoing orders</a></li>
										<li className="tab col s2"><a href="#orders-tab">Orders</a></li>
										<li className="tab col s2">
											<a href="#quotes-swipe-3" style={{position:'relative'}}>
											{user.notifications?.cotizaciones>0 &&
											<label style={{background:'red', borderRadius:5, padding:5, color:'white', fontWeight:'bold', position:'absolute', top:10, right:10, lineHeight:'normal', fontSize:9}}>{user.notifications.cotizaciones}</label>
											}
											Quotes
											</a>
										</li>
									</ul>
								
									<div id="test-swipe-1" className="col s12 padding-tab">
									{pedidos.filter(p=>p.envio.some(pedido_envio=>pedido_envio.status!=='Entregado')).length===0&&
									<h4>Not orders found</h4>
										
									}
									{pedidos.filter(p=>p.envio.some(pedido_envio=>pedido_envio.status!=='Entregado')).map((pedido,i) => (
										<div key={i} className="pad-10-0  container-tabs">
											<div className="tabs-info">
												<div className="bloque2">
													<div className="row margin-10" >
														<div className='col s12 m10 m-p-0 pad-0-14 pedido-card-fecha'>
															<div className='negro' style={{fontSize:24}}>Order-{String(pedido.id).padStart(4, 0)}</div>
															{pedido.envio[0]?.servicio!=='Recolección'&&
															<div className="negro tittle-mobile-center">Arriving on {pedido.envio.entrega}
																<span className='infocolor-text font-12 font-b-100'>
																	&nbsp;&nbsp; Consultation in 24 hours. The progress of the order
																</span>
															</div>
															}
															{pedido.envio[0]?.servicio==='Recolección'&&
															<div className="negro tittle-mobile-center">Branch delivery</div>
															}
														</div>
													</div>  
													<div className="row m-p-0 scroll-prod">
														{
														pedido.articulos.map((item,i) => (
														<div key={i} className="row m-p-0 card-pedido-lg">
															
															<div className='col hide-on-small-only m8 l9 m-p-0'>  
																<div className="text pad-5-0">Quantity: {item.cantidad}</div>
																<div style={{fontSize:'12px!important',fontWeight:'100!important', lineHeight:1,}}> Product: </div>
																<div className="card-tittle">  {item.nombre}</div>
																<div style={{fontSize:'12px!important',fontWeight:'100!important', lineHeight:1,}}> Description: </div>
																<div className="m-p-0 card-description">
																	{item.descripcion}</div>
															</div>
															<div className='col s12 m4 l3 img-card-pedido-cont'>
																<img className="img-card-pedido center" src={item.urls.length > 0 ? item.urls[0] : 'https://www.charlotteathleticclub.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png'} alt='' />
															</div>
														</div>
														))}
													</div>
													<div className="text m-0 row">
														<div className='col s12 m-p-0 pad-5-0 font-12'>
															<div className="cont-icon cont-icon-activo"> <i className="tiny material-icons m-p-0">check</i> </div>
															Ordered on {pedido.fecha}
															<div className={(['Pagado','Enviado','Entregado'].includes(pedido.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'} style={{marginLeft:20}}> 
																<i className="tiny material-icons m-p-0">check</i> 
															</div> 
															Paid
														</div>

														{pedido.envio.map((pedido_envio, i)=>
																<div key={i} className='row m-0' style={{borderBottom:'1px solid grey'}}>
														{pedido_envio.servicio!=='Recolección'?
														<>
														<div className='col s12 m3 m-p-0 pad-10-0 font-12 mobile-status'>
															<div className={(['Enviado','Entregado'].includes(pedido_envio.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> 
																<i className="tiny material-icons m-p-0">local_shipping</i> 
															</div> 
															Package-{i+1} send
														</div>
														<div className='col s12 m3 m-p-0 pad-10-0 font-12 mobile-status'>
															<div className={(['Enviado','Entregado'].includes(pedido_envio.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> 
																<i className="tiny material-icons m-p-0">check</i> 
															</div> 
															Package on the way
														</div>
														</>:
														<div className='col s12 m3 m-p-0 pad-10-0 font-12 mobile-status'>
															<div className={(['Empaquetado','Entregado'].includes(pedido_envio.status)) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> 
																<i className="tiny material-icons m-p-0">check</i> 
															</div> 
															Package ready for collect
															<div className='bold'>Address: {pedido_envio.direccion_recoleccion?.PickupLocation?.Address?.StreetLines[0]}, {pedido_envio.direccion_recoleccion?.PickupLocation?.Address?.StreetLines[1]}, {pedido_envio.direccion_recoleccion?.PickupLocation?.Address?.PostalCode}, {pedido_envio.direccion_recoleccion?.PickupLocation?.Address?.City} </div>
														</div>
														}
														<div className='col s12 m3 m-p-0 pad-10-0 font-12 mobile-status'>
															<div className={['Entregado'].includes(pedido_envio.status) ? 'cont-icon cont-icon-activo' : 'cont-icon'}> 
																<i className="tiny material-icons m-p-0">check</i> 
															</div> 
															Package delivered
														</div>
														</div>
														)}
													</div>
												</div>
											</div>
										</div>
										))}
										







									</div>


									<div id="orders-tab" className="col s12 padding-tab">
									{pedidos.length===0&&
									<h4>Not orders found</h4>
									}
									{pedidos.map((pedido,i) => (
										<div key={i} className="pad-10-0  container-tabs">
											<div className="tabs-info">
												<div className="row m-p-0 bloque1">
													<div className='col s12 m6 l3 m-p-0'>
														<div className='negro' style={{fontSize:24}}>Order-{String(pedido.id).padStart(4, 0)}</div>
														<div className="text">
															Ordered on:
															<div className="textos">on {pedido.fecha}</div>
														</div>
													</div>
													<div className='col m3 l2 m-p-0 text hide-on-small-only'>
														TOTAL:
														<div className="textos">${pedido.total} USD</div>
													</div>
													<div className='col s12 m3 l2 m-p-0 text'>
														Package sent to:
														<div className="destinatario">{pedido.envio[0]?.nombre}</div>
													</div>
													{!pedido.envio.every(s=>s.servicio==='Recolección')?
													<Link to={`/pedidos/pedido?pedido_id=${pedido.id}`} className="col s2 btn-azul rigth hide-on-med-and-down" style={{float:'right'}}> Follow order</Link>
													:
													<div className='bold'>Collect in: {pedido.envio[0]?.direccion_recoleccion?.PickupLocation?.Address?.StreetLines[0]}, {pedido.envio[0]?.direccion_recoleccion?.PickupLocation?.Address?.StreetLines[1]}, {pedido.envio[0]?.direccion_recoleccion?.PickupLocation?.Address?.PostalCode}, {pedido.envio[0]?.direccion_recoleccion?.PickupLocation?.Address?.City}
													</div>
													}
												</div>
												<div className="bloque2 m-0 scroll-item">
													{pedido.articulos.map((item,i) => (
														<div key={i} className="row m-0 pad-b-20">
															<div className='col s5 m3 l2 m-p-0 img-prod'>
																<Link to={`/product?codigo=${item.codigo}`} tabIndex="-1" className="ligaProd">
																	<div className="img-prod-hover">See product<br></br>details</div>
																	<img src={item.urls.length > 0 ? item.urls[0] : 'https://www.charlotteathleticclub.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png'} alt='' />
																</Link>
															</div>
															<div className='col s7 m9 l10 m-p-0 border-top'>
																<div className="negro">{item.nombre}</div>
																<div className="m-p-0 scroll-descripcion">&nbsp; {item.descripcion}</div>
																<div className="text pad-5-0 hide-on-small-only">
																	Quantity {item.cantidad}<br></br>
																	Price ${item.precio}
																</div>
																{!item.comentado&&
																<div className='col m4 m-p-0' style={{ textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center'}}> 
																	<Link to={`/reviews?codigo=${item.codigo}&pedido_id=${item.pedido_id}`} className='btn-azul m-p-0 hide-on-med-only hide-on-med-and-down' style={{height:'40px', margin:'0!important', padding:'0!important', width:'80%'}}>Evaluate Product</Link>
																</div>
																}
															</div>
															<div className="col s12 text m-p-0 show-on-small-only hide-on-med-and-up">
																<div className="col s6 m-p-0">
																	<div className="text margin-top-5">
																		Quantity {item.cantidad} </div>
																</div>
																<div className='col s6 m-p-0 pad-0-14 font-12'>
																	<div className="cont-icon margin-top-5">
																		<i className="tiny material-icons m-p-0">check</i>
																	</div> 
																	{pedido.status}
																</div>
																<div className='col s12 linfocolor-text font-b-500 font-16 m-p-0 right-align'>
																	<div className="margin-10 infocolor-text orange-brick">Total: {Math.floor(item.cantidad * item.precio).toFixed(2)} <span>USD</span></div>
																</div>
															</div>	
															{/*
															<button className='col s12 padding-10 btn-negro show-on-medium-and-down hide-on-med-and-up margin-top-10' onClick={()=>_recomprar(item.cantidad,item.codigo)} style={{lineHeight:1,}}> Buy again </button>
															<button className='col s2 btn-negro rigth hide-on-med-only hide-on-med-and-down' onClick={()=>_recomprar(item.cantidad,item.codigo)} style={{float:'right',lineHeight:1,}}> Buy again </button>
															*/}
															
														</div>
														))
													}
													<Link to={`/pedidos/pedido?pedido_id=${pedido.id}`} className="col btn-azul btn-azul-mobile s12 show-on-medium-and-down hide-on-large-only" style={{marginTop:'10px'}}> Follow order</Link>

												</div>

												<div className="text row hide-on-small-only" style={{background:'#ffffff',margin:0, padding:'20px 0!important', paddingBottom:'20px!important', height:40}}>
													<div className='col m3'></div>
													<div className='col m3 linfocolor-text font-b-300 font-14 m-p-0 orange-brick' 
													style={{padding:0,}}>
														Subtotal ${pedido.subtotal} 
													</div>
													<div className='col m3 linfocolor-text font-b-300 font-14 m-p-0 orange-brick' 
													style={{padding:0,}}>
														Packaging price ${pedido.total_envio}
													</div>
													<div className='col m3 m-p-0 pad-0-14 font-12'>
														<div className="cont-icon"> 
															<i className="tiny material-icons m-p-0">check</i> 
														</div> 
														<span className="linfocolor-text font-b-700 font-14 m-p-0">
															{pedido.envio.every(pedido_envio=>pedido_envio.status==='Entregado')?'Delivered':'Pending'}
														</span>
													</div>
												</div>	
											</div>
										</div>
									))}
									</div>

									<div id="quotes-swipe-3" className="col s12 padding-tab">
									{cotizaciones.sort((a, b) => a.user_view - b.user_view).map(c=>
										<div key={c.id} style={{background:'white', padding:20, border:'solid 1px #D6D6D6', marginBottom:20, boxShadow: c.user_view?'':'2px 2px 12px 3px rgba(204, 255, 204, 0.97)'}}>
											<div className='row'>
												<div className='col s3'>
													<h5>Quote-{String(c.id).padStart(4, 0)}</h5>
													<div>Quoting product: {c.object_ref.producto.nombre}</div>
													<div>Quantity:{c.cantidad}</div>

													<div className='title-1'>Supplier: {c.object_ref.producto.company_name}</div>
													<ul className="collection">
													{c.object_ref?.producto?.proveedor?.contact_persons.filter((c)=>c.name).map((p,i)=>
														<li key={i} className="collection-item avatar">
															<i className="material-icons circle red">verified_user</i>
															<span className="title">{p.name}</span>
															<p>	{p.position}<br />
																Second Line
																Tel: {p.telephone}<br />
																Email: {p.email}<br />
																Whatsapp: {p.whatsapp}<br />
															</p>
															<a href="#!" className="secondary-content"><i className="material-icons">grade</i></a>
														</li>
													)}
													</ul>
												</div>
												<div className='col s9'>
													<div id='chat' style={{border:'solid 1px #D6D6D6', height:400, padding:20, overflow:'auto'}}>
													{c.conversation.messages?.map((m, i)=>
														<div key={i} className='row' style={{background:user.name===m.sender_name?'white':'#D3D7CF'}}>
															<div className='col s1'>
																<div>
																	<i className="material-icons circle medium">account_circle</i>
																</div>
																{m.sender_name}
															</div>
															<div className='col s10'>
																<label>{new Date(m.created_at).toLocaleString('es-MX', { timeZone: "America/Mexico_City" })}</label>
																<p>
																{m.content}
																</p>
																{m.files?.map((f,i)=>
																<div key={i}>
																	<a href={f} target='_blank' rel='noreferrer'>{f.split('/')[f.split('/').length-1]}</a>
																</div>
																)}
																	
															</div>
														</div>
													)}
													</div>
													<div className="row">
														<form onSubmit={(e)=>_cotizacion_mensaje(e, c)} className="col s12">
															<div className="row">
																<div className="file-field input-field col s1">
																	<div className="btn">
																		<i className="material-icons">attach_file</i>
																		<input type="file" onChange={(e)=>setCotizaciones(cotizaciones.map(cc=>c.id===cc.id?{...cc, dataForm:{...cc.dataForm, files:cc.dataForm?.files?[...cc.dataForm.files, e.target.files[0]]:[e.target.files[0]] }}:cc ))}/>
																	</div>
																	<div className="file-path-wrapper" style={{display:'none'}}>
																		<input className="file-path validate" type="text" />
																	</div>
																</div>
																<div className="input-field col s8">
																	<i className="material-icons prefix">mode_edit</i>
																	<textarea id="icon_prefix2" onClick={()=>_visto(c)} className="materialize-textarea" name='mensaje' required></textarea>
																	<label htmlFor="icon_prefix2">Message</label>
																</div>
																<div className="col s2">
																	<button className="btn waves-effect waves-light" type="submit" name="action" style={{marginTop:15}}>Send
																		<i className="material-icons right">send</i>
																	</button>
																</div>
															</div>
															{c.dataForm?.files?.map((f,i)=>
															<div key={i}>
																imagen {f.name}	
															</div>
															)}
														</form>
													</div>
												</div>
											</div>
										</div>
									)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				}
			</div>
		);
};

export default Pedidos;
