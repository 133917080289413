import React, { useState, useEffect } from 'react';
import axios from '../../../../infrastructure/axios'
import { Link } from 'react-router-dom'
import Loader from '../../../../components/Loader';

const Departament = () => {

	const [loader, setLoader] = useState(true)
	const [industrias, setIndustrias] = useState([])

	useEffect(()=>{
		setLoader(true)
		axios.get('/api/reseller/get_all_industry_departaments')
		.then(r=>{
			setIndustrias(r.data)
			setLoader(false)
		})
		.catch((r)=>alert(r))
	},[])

	return(
		<>
		
		{loader?
			<Loader />
			:
			<div style={{pading:50, margin:50}}>
				{industrias.map((industria,i)=>
				<div className='card-panel'key={i} style={{marginBottom:40}}>
				<div className='row' style={{display:'flex', justifyContent:'left', alignItems:'center', minHeight:200}}> 
					<div className='col s4'>
						<h4 style={{textAlign:'center'}}>{industria.industria}</h4>
					</div>
					<div className='col s8'>
					<div className='row'>
					{industria.departamentos.map((departamento,i)=>
					<div className='col s3' key={i}>
						<div style={{fontWeight:'bold', fontSize:18}}>{departamento.departamento}</div>
						{departamento.categorias.map((categoria, i)=>
						<div key={i}>
							<Link href={`/cat?categoria_id=${categoria.id}&departamento_id=${categoria.departamento_id}`}> {categoria.categoria}</Link>
						</div>
						)}
					</div>
					)}
					</div>
					</div>
				</div>
				</div>
				)} 
			</div>
		}
		</>
	)


}


export default Departament
