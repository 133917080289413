import React, { useState, useContext } from 'react';
import { DataContext } from '../../../../contexts/DataContext'
import { useNavigate, Link } from 'react-router-dom'
import axios from '../../../../infrastructure/axios';
import './registro.css';
import user from './userFig.png'
import Loader from '../../../../components/Loader'

const Registro = () => {

    const { setUser } = useContext(DataContext)
    const [data, setData] = useState({name:'',email:'',password:'',password_confirmation:''})
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false)

    const singUp = (event) =>{
        event.preventDefault()
        setLoader(true)
        if(data.password === data.password_confirmation){
            axios.post('/api/signup',{...data, domain: window.location.hostname})
            .then(r => {
                    setUser(r.data.user)
                    localStorage.setItem('access_token', 'Bearer '+r.data.access_token);
                    axios.defaults.headers.common['Authorization'] = 'Bearer '+r.data.access_token
                    //setAuth(true)
                    setLoader(false)
                    navigate('/')
    
            }).catch(err=>{
                setLoader(false)
                if(err.response.data.errors.email){
                    alert(err.response.data.errors.email[0])
                }else
                alert(err.response.data.message)
                    setLoader(false)
            }) 
        }else{
            alert("Los passwords no coinciden")
                   setLoader(false)
        }
           
    }
    if(loader)
	    return (
		<div style={{minHeight:'80vh'}}>
			<Loader />
		</div>
	)
	    	

    if(loader)
    return(
	<Loader />
	)

    return (
        <div>
            <div className='row m-p-0 height80 center-y-x'>
                <div className='col s12 m5 m-p-0 center-y-x'>
                    <div className='card-panel'>
                        <div className='center-y-x'>
                            <div className='cont-user'>
                                <img src={user} width='80' height='80' alt=''/>
                            </div>
                        </div>
                        <div className='s12 center-y-x'>
                            <p className='store-name'> Crea una cuenta y disfruta de los Beneficios de </p>
                        </div> 
                        <form  onSubmit={singUp} className='col s12'>
                            <div className='input-fieldi col s12 p-0'>
                                <label className='black-text' htmlFor='name'>Nombre</label>
                                <input onChange={(e)=>setData({...data, name:e.target.value})} id='name' type='text' name='name' style={{border:'1px solid #D6D6D6', borderRadius:5}}/>
                            </div>
                            <div className='input-fieldi col s12 p-0'>
                                <label className='black-text' htmlFor='email'>Correo Electrónico*</label>
                                <input onChange={(e)=>setData({...data, email:e.target.value})} id='email' type='email' style={{border:'1px solid #D6D6D6', borderRadius:5}}/>
                            </div>
                            <div className='input-fieldi col s12 p-0'>
                                <label className='black-text' htmlFor='password'>Contraseña*</label>
                                <input onChange={(e)=>setData({...data, password:e.target.value})} id='password' type='password' style={{border:'1px solid #D6D6D6', borderRadius:5}}/>
                            </div>
                            <div className='input-fieldi col s12 p-0'>
                                <label className='black-text' htmlFor='password_confirmation'>Confirmar contraseña*</label>
                                <input onChange={(e)=>setData({...data, password_confirmation:e.target.value})} id='password_confirmation' type='password' style={{border:'1px solid #D6D6D6', borderRadius:5}}/>
                                {(data.password!==data.password_confirmation)&&<span className='helper-text  red-text text-lighten-2'>Los passwords no coinciden</span>}
                            </div>
                            <button className='waves-effect waves-light primarycolor white-text btn col s12'  type='submit' name='action'>Registrarme</button>
                        </form>
                        <div className='col m12' style={{marginTop:'1em'}}>
                            ¿Ya tienes cuenta? <Link className='font-red infocolor-text' to='/login'> Inicia Sesión </Link>
                        </div>
                        <div className='col m12' style={{marginBottom:'1em'}}>
                            <Link className='font-red infocolor-text' to='/recuperar'>Olvide mi contraaseña</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    )
}

export default Registro;
