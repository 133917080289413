import React from 'react';
import axios from '../../../../infrastructure/axios';
import GoodPNG from './good.png'

const Resenia = (props) => {

	const {codigo, comentarios, resena, setComentarios} = props;

	const _likereview = (id) =>{
		console.log(id)
		axios.post('/api/likereview',{codigo:codigo, comentario_id:id})
		.then(r=>{
			setComentarios(comentarios.map(c=>c.id===id?({...c,likes_comentario_count:c.likes_comentario_count+((r.data.tipo === 'like')?1:-1)}):c))
		}).catch(e=>console.log('error',e))
	}

	const _renderStars = (calificacion = 0) =>{
		let icons =[]
		for(let i = 0;i<5;i++){
			icons.push(<i key={i} className="material-icons" style={{fontSize:12, color:(i+1<=calificacion?'#FA9C1C':'#D5D5D5') }}>star</i>)
		}
		return icons;
	}

	const _reportar = (id) =>{
		console.log('reportado');
		axios.post('/api/comentario_reporte',{comentario_id:id})
		.then(r=>{
			console.log('reportado',r.data)
		}).catch(e=>console.log('error',e))
	}

	return (
		<div style={{display:'block'}}>
			<div className='row m-0 contain-detail resenias'>
				<div className='row resenia-content'> 
					<h4>Rating</h4>
					<div>Star rating {resena} of 5 {_renderStars(resena)}</div>
					<div style={{fontWeight:900}}>Comments</div> 
				</div>
				{comentarios.map((com, i) => 
				<div key={i} className='row resenia-content'> 
					<div className='row resenia-bg'> 
						<div className='col s12 m11 row m-p-0'>
							<div className='row m-p-0'>
								<div className='col s2 m1 m-p-0 cont-foto'>
									<img id='src-picture' alt='user' className="responsive-img perfil-img foto-img" src={`${axios.defaults.baseURL}/users/${com.user.id}.png`} onError={(e)=>{e.target.onerror = null; e.target.src="https://cdn-icons-png.flaticon.com/512/121/121693.png"}}/>
								</div>
								<div className='col s10 m11 m-p-0'>
									{com.user.name}<br></br>
									<div className='m-p-0'>
										{_renderStars(com.calificacion)}
									</div>
								</div>
							</div>
							<p className='coment-nombre'>{com.titulo} </p>
							<p style={{fontSize:11, lineHeight:1}}> {com.comentario} </p>
						</div>
						<div className='col s12 m1 row m-p-0'>
							<div className='col s6 m12 row m-p-0 textR'>
								<div className='col s8 m8 row m-p-0'><span className='num-like'>{com.likes_comentario_count}</span></div>
								<div className='col s4 m4 row m-p-0' style={{cursor:'pointer',}} onClick={()=>_likereview(com.id)}>	
									<img src={GoodPNG} style={{height:'20px', width:'26px'}} className="filtros-demo" />
								</div>
							</div>
							<div className='col s6 m12' style={{textAlign:'right', padding:'20px 0 0 0',}}>
								<div className='col m-p-0' style={{ textAling:'center',width:'100%'}}>
									<div className='col s12 m-p-0' style={{cursor:'pointer', lineHeight:0}} onClick={()=>_reportar(com.id)}>	
										<i className="material-icons m-p-0" style={{width:'100%'}}>speaker_notes_off</i>
										<span className='' style={{textAlign:'right', padding:0, marginTop:'-20px!important', fontSize:8}}> Reportar </span>
									</div>
								</div>
							</div>

						</div>
					</div>
				</div>
				)}
			</div>
		</div>
	)
};

export default Resenia;
