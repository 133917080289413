import React, { useEffect, useContext, useState } from 'react';
import { UserContext } from '../contexts/UserContext';
import { axios } from '../infrastructure/axios';
import { Link, useNavigate, Outlet } from "react-router-dom";
import M from 'materialize-css'
import Wizard from './Wizard'

const Dashboard = () => {

	const navigate = useNavigate();
	const { user, setAuth, setUser, configs, setConfigs } = useContext(UserContext);
	const [loader, setLoader] = useState(true)
	const [conversations, setConversations] = useState([])
	
	useEffect(()=>{
		const fetchData = async () => {
			try {
				/**
				  * config data
				  */
				const getConfig = await axios.get('/api/reseller/config');
				setConfigs(getConfig.data)
	
				/**
				  * user data
				  */
				const getUser = await axios.get('/api/user');
				setUser(getUser.data)	

				/**
				  * Mensajes
				  */
				const getMessages = await axios.get('/api/message/my_messages');
				setConversations(getMessages.data.conversations)

				/**
				  * check para mostrar mensaje
				  */
				const getCheckShowModal = await axios.get('/api/my_catalogo_count');
				setLoader(false)

				if(getCheckShowModal.data.my_catalogo_count===0){
					setTimeout(()=>{
						M.Modal.init(document.querySelectorAll('#modal-important'), {});
						let instance = M.Modal.getInstance(document.querySelector('#modal-important'));
						instance.open()
					},2000)
				}

				M.AutoInit()
	

			} catch (error) {
				if(error.response.status !== 401){
					alert(error);
				}
			}
		};
		fetchData();

	}, [])

	
	const _salir=()=>{
		axios.get('/api/logout')
		.then(r=>{
			localStorage.setItem('access_token', '');
			setAuth(false)
			navigate('/admin/login')
		})
	}
	
	const Hola=()=>{
		return (
			<div className="card-panel">
				<h4>BIENVENIDO RESELLER {user.name.toUpperCase()}</h4>
			</div>
		)
	}

	if(loader) return (
		<div className="progress">
			<div className="indeterminate"></div>
		</div>
	)
	
	if(!configs.config_store.wizard_config.installed)
	return (
		<Wizard configs={configs}/>
	)

	return (
		<>
		<div id="modal-important" className="modal">
			<div className="modal-content">
				<h4>Important</h4>
				<p>Please enter your company information in the SETTINGS option under MY DATA. Once completed, proceed to upload a product.</p>
			</div>
			<div className="modal-footer">
				<a href="#!" className="modal-close waves-effect waves-green btn-flat">Ok</a>
			</div>
		</div>

		<div className="row">
			<div className="col s2" id="menu" style={{width: '250px'}}>
				<ul id="slide-out" className="sidenav collapsible sidenav-fixed" style={{width: '250px',backgroundColor:'#2E3436', border:0}}>
					<li className='center' style={{padding:'20px'}}>
						<img width='150' src={configs.config_store.logo} alt='recurso' style={{objectFit:'scale-down',width:'100%',boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)'}}/>
					</li>
					<li>
						<Link to='/admin' className='collapsible-header' style={{display:'flex', alignItems:'center', color:'white'}}>
							<i className="material-icons white-text">home</i> Home
						</Link>
					</li>
					<li>
						<div className="collapsible-header menu-title white-text">
							<i className="material-icons">attach_money</i>Balance
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/admin/balance/summary" className="waves-effect"><i className="material-icons">playlist_add_check</i>Summary</Link></li>
							<li><Link to="/admin/balance/payment-history" className="waves-effect"><i className="material-icons">format_list_bulleted</i>Payment history</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header menu-title white-text">
							<i className="material-icons">library_books</i>Visits
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/admin/visitas" className="waves-effect"><i className="material-icons">loyalty</i>Dairy</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<Link to='/admin/mensajes' className='collapsible-header' style={{display:'flex', alignItems:'center', color:'white'}}>
							<i className="material-icons white-text" style={{marginRight:'1rem'}}>chat</i>Chat
							{conversations.flatMap(c=>c.messages).filter((m)=>!m.visto).reduce((a,b)=>a+=b.sender_name===user.name?0:1, 0)>0&&
							<label style={{background:'red', borderRadius:5, padding:5, color:'white', fontWeight:'bold', lineHeight:'normal', fontSize:9, margin:5}}>{conversations.flatMap(c=>c.messages).filter((m)=>!m.visto).reduce((a,b)=>a+=b.sender_name===user.name?0:1, 0)}</label>
							}
						</Link>
					</li>
					<li>
						<div className="collapsible-header menu-title white-text">
							<i className="material-icons">library_books</i> Inventory
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/admin/dropshiping" className="waves-effect"><i className="material-icons">add_shopping_cart</i>DropShiping</Link></li>
							<li><Link to="/admin/catalogo" className="waves-effect"><i className="material-icons">loyalty</i>My catalog</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">assignment_turned_in</i> Quotes
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/admin/cotizaciones" className="waves-effect"><i className="material-icons">storage</i>Quotes list</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">local_atm</i> Orders
							{user.notifications?.pedidos_pendientes>0&&
							<label style={{background:'red', borderRadius:5, padding:5, color:'white', fontWeight:'bold', lineHeight:'normal', fontSize:9, margin:5, height:20}}>{user.notifications?.pedidos_pendientes}</label>
							}
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
						<ul>
							<li><Link to="/admin/ventas" className="waves-effect"><i className="material-icons">shopping_cart</i>My Orders</Link></li>
						</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">settings_applications</i> Settings
							<i className="material-icons chevron_right" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
							<ul>
								{/*<li><a href="#!" className="subheader">Configuración</a></li>*/}
								<li><Link to="/admin/mis-datos" className="waves-effect"><i className="material-icons">store</i>My data</Link></li>
								<li><Link to="/admin/mi-tienda" className="waves-effect"><i className="material-icons">store</i>My store</Link></li>
								<li><Link to="/admin/website" className="waves-effect"><i className="material-icons">store</i>WebSite</Link></li>
								<li><Link to="/admin/comments" className="waves-effect"><i className="material-icons">comment</i>Comments</Link></li>
								<li><Link to="/admin/pickups" className="waves-effect"><i className="material-icons">local_shipping</i>Pickups</Link></li>
								<li><Link to="/admin/my-account-bank" className="waves-effect"><i className="material-icons">credit_card</i>My Account Bank</Link></li>
								<li><Link to="/admin/domains" className="waves-effect"><i className="material-icons">cloud</i>Domains</Link></li>
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">work</i> Policies
							<i className="material-icons" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
							<ul>
								<li><Link to="/admin/aviso-privacidad" className="waves-effect"><i className="material-icons">account_balance</i>Privacy Policy</Link></li>
								<li><Link to="/admin/politica-compra" className="waves-effect"><i className="material-icons">assignment_turned_in</i>Purchasing Policy</Link></li>
								<li><Link to="/admin/pagos-y-envios" className="waves-effect"><i className="material-icons">send</i>Payments and Shipments</Link></li>
								
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">help</i> Ayuda
							<i className="material-icons" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
							<ul>
								<li><Link to="/admin/faq" className="waves-effect"><i className="material-icons">feedback</i>FAQs</Link></li>
								<li><Link to="/admin/glosario" className="waves-effect"><i className="material-icons">extension</i>Glosario</Link></li>
							</ul>
						</div>
					</li>
					<li>
						<div className="collapsible-header white-text">
							<i className="material-icons">card_membership</i> Suscription
							<i className="material-icons" style={{position:'absolute', right:0}}>chevron_right</i>
						</div>
						<div className="collapsible-body">
							<ul>
								<li><Link to="/admin/suscripcion" className="waves-effect"><i className="material-icons">feedback</i>Status</Link></li>
							</ul>
						</div>
					</li>
					<li>
						<a href="#!" onClick={_salir} className="waves-effect white-text" style={{padding:0, margin:0, display:'block', alignItems:'center'}}>
							<i className="material-icons white-text" style={{padding:0, margin:'0 10px 0 0',}}>exit_to_app</i>Exit
						</a>
					</li>
				</ul>
				<a href="#!" data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></a>
			</div>
			<div className="col s10">
				<Outlet />
			</div>
		</div>
		</>
	)
}

export default Dashboard
