import React, { useState, useContext } from 'react';
import './footer.css';
import axios from '../../infrastructure/axios';
import { Link } from 'react-router-dom';
import { DataContext } from '../../contexts/DataContext';
import M from 'materialize-css'

const Footer = () => {

	const { config } = useContext(DataContext)
	const [data , setData] = useState({nombre:'',correo:'',mensaje:''})
	const [boletin, setBoletin] = useState({correo:''})
	const currentTime = new Date();
	const year = currentTime.getFullYear()
	console.log(config)

	//Envia formulario de contacto
	const sendMessage = (e) =>{
		e.preventDefault()
		axios.post('/api/reseller/contactoweb',data).then(r=>{
			setData({nombre:'',correo:'',mensaje:''})
			M.toast({html:'<span> &nbsp; Mensaje enviado <i class="material-icons">check_circle</i></span>', classes :'green'})
		}).catch(e=>M.toast({html:'<span> &nbsp; No se pudo enviar  &nbsp;<i className="material-icons">error</i></span>', classes :'red accent-4'}))
	} 
	//Registro al boletin
	const sendBoletin = (e) =>{
		e.preventDefault()
		axios.post('api/reseller/boletin',boletin).then(r=>{
			setBoletin({correo:''})
			M.toast({html:`<span>${r.data.messagge}</span>`, classes :'green'})
		}).catch(e=>alert(e))
	}

	return(
		<footer className='page-footer footerbackground infocolor-background'>
			<div className='footer-center'>
				<div className='row pt-40'>
					<div className='col cont-footer-form es-pc'>
						<form onSubmit={sendMessage}>
							<p className='footerForm m-p-0 footertext'>Nombre*</p>
							<div className='col s12  m-p-0'>
								<input onChange={(e)=>setData({...data, nombre: e.target.value})} value={data.nombre} id='nombre' type='text' className='height33 footerForm-input validate' required/>
							</div>
							<p className='footerForm m-p-0 padding-top-50 footertext'>Correo*</p>
							<div className='col s12  m-p-0'>
								<input onChange={(e)=>setData({...data, correo: e.target.value})} value={data.correo} id='correo' type='email' className='height33 footerForm-input validate' required/>
							</div>
							<p className='footerForm m-p-0 padding-top-50 footertext'>Mensaje*</p>
							<div className='col s12  m-p-0'>
								<textarea onChange={(e)=>setData({...data, mensaje: e.target.value})} value={data.mensaje} id='mensaje' className='materialize-textarea footerForm-input footerText validate' required/>
							</div>
							<button className='col s12 btn waves-effect waves-light btn-footer-send primarycolor footertext' type='submit' name='action'> 
								Enviar
								<i className='material-icons right'>send</i>
							</button>
						</form>
					</div>

					<div className='col cont-footer'>
						<div className='footerTitle footertext'>Servicio al cliente</div>
						<ul className='m-p-0'>
							<li>
								<p className='footertext footerTexto'>Envíanos un correo a:</p>
								<a className='footerLink footertext' href={'mailto://'+config.emailserviciocliente}>{config.emailserviciocliente}</a></li>
							<p className='footertext footerTexto'>
								{config.configs &&
									<>
									{config.configs.horario.diaInicio} a&nbsp; 
									{config.configs.horario.diaFin}:&nbsp; 
									{config.configs.horario.horaInicio}&nbsp;am a&nbsp; 
									{config.configs.horario.horaFin}&nbsp;pm
									</>
								}
							</p>
						</ul>

						<div className='footertext footerTex pt-40 es-pc'> Únete a la familia <span className='store-name' /> para recibir noticias y novedades de nuestros productos </div> 
						<div className='row s12 es-pc'>
							<form onSubmit={sendBoletin}>
								<div className='col s6 m9 m-p-0'>
									<input onChange={(e)=>setBoletin({...boletin, correo:e.target.value})} value={boletin.correo} 
										id='unete' type='email' className='height33 footer-input validate' placeholder="ejemplo@micorreo.com" />
								</div>
								<div className='col s6 m2 m-p-0'>
									<button className='col btn waves-effect waves-light btn-footer-news btn-footer-send primarycolor' type='submit' name='action' >
										<i className='material-icons right btn-footer-news m-p-0 footertext'>send</i>
									</button>
								</div>
							</form>
						</div>
					</div>
					<div className='es-movil' style={{scrollPadding:'10px 0',width:'100%!important',}}>
						{((config.configs?.visible_redes?.facebook===1&&config.url_facebook)||(config.configs?.visible_redes?.instagram===1&&config.url_instagram)||(config.configs?.visible_redes?.youtube===1&&config.url_youtube))&&
							<div className='footerTitle red-center'>Redes Sociales</div>
						}
						{(config.configs?.visible_redes?.facebook===1&&config.url_facebook)&&
						<div className="col s4">
							<a href={config.url_facebook.includes('://')?config.url_facebook: `https://${config.url_facebook}`} target="_blank" rel="noreferrer" className="red-right" style={{verticalAlign:'middle', display:'flex'}}>
								<img src={config.icon_facebook} alt='facebook' width='20'/>
								<span style={{paddingLeft:'8px',}}> Facebook </span>
							</a>
						</div>
						}
						{(config.configs?.visible_redes?.instagram===1&&config.url_instagram)&&
						<div className="col s4">
							<a href={config.url_instagram.includes('://')?config.url_instagram: `https://${config.url_instagram}`} target="_blank" rel="noreferrer" className="red-center" style={{verticalAlign:'middle', display:'flex'}}>
								<img src={config.icon_instagram} alt='facebook' width='20'/>
								<span style={{paddingLeft:'8px',}}> Instagram </span>
							</a>
						</div>
						}
						{(config.configs?.visible_redes?.youtube===1&&config.url_youtube)&&
						<div className="col s4">
							<a href={config.url_youtube.includes('://')?config.url_youtube: `https://${config.url_youtube}`} target="_blank" rel="noreferrer" className="red-center" style={{verticalAlign:'middle', display:'flex'}}>
								<img src={config.icon_youtube} alt='facebook' width='20'/>
								<span style={{paddingLeft:'8px',}}> Youtube </span>
							</a>
						</div>
						}
					</div>
					<div className='col cont-footer-link'>
						<div className='footerTitle store-name footertext'></div>
						<ul className='m-p-0'>
							<li><Link className='footerLink footertext' to='/aboutus'>¿Quiénes somos?</Link></li>
							<li><Link className='footerLink footertext' to='/aviso'>Aviso de privacidad</Link></li>
							<li><Link className="footerLink footertext" to='/bolsa_trabajo'>Trabaja con nosotros</Link></li>
						</ul>
					</div>
					<div className='col cont-footer-link'>
						<div className='footerTitle footertext'>Para Clientes</div>
						<ul className='m-p-0'>
							<li><Link className='footerLink footertext' to='/faq'>FAQ</Link></li>
							<li><Link className='footerLink footertext' to='/glosario'>Glosario</Link></li>
							<li><Link className="footerLink footertext" to='/pagos_envios'>Pagos y envíos</Link></li>
							<li><Link className="footerLink footertext" to='/politicas_compras'>Políticas de compra</Link></li>
						</ul>
					</div>
					<div className='col cont-footer-link es-pc'>
						{((config.configs?.visible_redes?.facebook===1&&config.url_facebook)||(config.configs?.visible_redes?.instagram===1&&config.url_instagram)||(config.configs?.visible_redes?.youtube===1&&config.url_youtube))&&
						<div className='footerTitle footertext'>Redes Sociales</div>
						}
						{config.configs&&
						<ul className='m-p-0'>
							{config.configs.visible_redes.facebook ===1 &&
							<li>
								<a className="footerLink footertext" target="_blank" rel="noreferrer" href={config.url_facebook.includes('://')?config.url_facebook: `https://${config.url_facebook}`} style={{verticalAlign:'middle', display:'flex'}}>
									<img src={config.icon_facebook} alt='facebook' width='20'/>
									<span style={{paddingLeft:'8px',}}> Facebook </span>
								</a>
							</li>
							}
							{config.configs.visible_redes.instagram ===1 &&
							<li>
								<a className="footerLink footertext" target="_blank" rel="noreferrer" href={config.url_instagram.includes('://')?config.url_instagram: `https://${config.url_instagram}`} style={{verticalAlign:'middle', display:'flex'}}>
									<img src={config.icon_instagram} alt='facebook' width='20'/>
									<span style={{paddingLeft:'8px',}}> Instagram </span>
								</a>
							</li>
							}
							{config.configs.visible_redes.youtube ===1 &&
							<li>
								<a className="footerLink footertext" target="_blank" rel="noreferrer" href={config.url_youtube.includes('://')?config.url_youtube: `https://${config.url_youtube}`} style={{verticalAlign:'middle', display:'flex'}}>
									<img src={config.icon_youtube} alt='facebook' width='20'/>
									<span style={{paddingLeft:'8px',}}> Youtube </span>
								</a>
							</li>
							}
						</ul>
						}
					</div>
				</div>
			</div>
			<div className='footer-copyright  footertext footer-inferior'>
				<div className='container' style={{textAlign:'center', fontSize:'11px'}}>
					<div className='store-name' style={{fontWeight:'bold', fontSize:'14px'}}></div>
					© {year} <span className='store-name'></span> México. Todos los derechos reservados 
				</div>
			</div>
		</footer>
	)
}

export default Footer
