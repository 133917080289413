"use client"

import './productCard.css'
import { Link } from 'react-router-dom'
import diamond from './diamond_member.png'
import audited from './as_audited.png'

/**
  *
  * @param [Object] producto
  * @param [String] producto.codigo
  * @param [String] producto.nombre
  * @param [String] producto.descripcion
  * @param [Array] producto.imagenes
  * @param [Decimal] producto.precio
  * @param [Integer] producto.stock
  *
  */
const ProductCard = ({producto}) => {

	console.log(producto)

	return (
		<>
		<div className='prod-card'>
			<div className="prod-container" style={{width: 300}}>
				<Link to={`/product?codigo=${producto.codigo}`} style={{margin:'0!important', padding:'0!important', width:'100%', display:'block', background:'red !important'}}>
				<center className="prod-position" style={{position:'relative', zIndex:'1'}}>
					<div className='bold center-y-x prod-cont-img'>
						<img className="" src={producto.imagenes.length?producto.imagenes[0].url:'https://www.charlotteathleticclub.com/assets/camaleon_cms/image-not-found-4a963b95bf081c3ea02923dceaeb3f8085e1a654fc54840aac61a57a60903fef.png'} alt='' />
					</div>
				</center>
				<div className="prod-info">
					<div className="row m-p-0">
						<span className="prod-name" style={{color:'black'}}>
							<img border="0" alt="" src="https://www.micstatic.com/mic-search/img/featured-icon_ee6b6124.png" style={{marginRight:5}}/>
							{producto.nombre}
						</span>
						<p className="col s12 prod-descripcion">{producto.descripcion?producto.descripcion:'Descripción no disponible'}</p>
					</div>
					<div className="m-p-0" style={{borderRadius:' 0 0 12px 12px', position:'relative'}}>
						<div style={{color:'grey', float:'left'}}>FOB Price: </div>
						<div className='' style={{color:'black', display:'flex'}}>
							<div style={{color:'#D92617'}}>USD ${producto.purchase_info?.minimum_order_quantity[0]?.price}-{producto.purchase_info?.minimum_order_quantity[3]?.price!==''?producto.purchase_info?.minimum_order_quantity[3]?.price:(producto.purchase_info?.minimum_order_quantity[2]?.price!==''?producto.purchase_info?.minimum_order_quantity[2]?.price:(producto.purchase_info?.minimum_order_quantity[1]?.price!==''?producto.purchase_info?.minimum_order_quantity[1]?.price:producto.purchase_info?.minimum_order_quantity[0]?.price))}</div>
							<div>/Piece</div>
						</div>	
						<div>
							<div style={{color:'grey', float:'left'}}>Min. Order:</div>
							<div style={{color:'black'}}>{producto.purchase_info?.minimum_order_quantity[0]?.rango?.split('-')[0]} Pieces</div>
						</div>
					</div>
				</div>
				</Link>
				<div style={{padding:'0px 17px'}}>
					<img alt='diamod' src={diamond} style={{width:20, margin:5}}/>
					<Link to={`/proveedor?name=${producto.proveedor?.company_name}`}>
						<img alt='audited'src={audited} style={{width:20, margin:5}} />
					</Link>
				</div>
			</div>
		</div>
		</>
	);
};

export default ProductCard;
