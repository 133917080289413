import React, {useContext, useState, useEffect} from 'react';
import Nav from '../../components/Nav'
import './reviews.css';
//import { useSearchParams } from 'next/navigation' //nextjs
import { useLocation } from 'react-router-dom'; //reactjs
import { axios } from '../../infrastructure';
import Loader from '../../components/Loader'
import { DataContext } from '../../contexts/DataContext'
import ReviewGif from './reviwer.gif'

const Reviews = () => {

    const { user} = useContext(DataContext)
    const [loader,setLoader] = useState(false)
    const [producto, setProducto] = useState({imagenes:[]})
    const [imgSelect, setImgSelect] = useState({})
    const [comentario, setComentario] = useState({codigo:'', calificacion:0, titulo:'', comentario:''})

    //nextjs
    //const codigoParams = useSearchParams()
    //const codigo = codigoParams.get('codigo')

    //reactjs
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const codigo = searchParams.get('codigo');
    const pedido_id = searchParams.get('pedido_id')
    
    useEffect(() => {
	const fetchData = async () => {
		try {
			// Fetch Producto
			const productoResponse = await axios.get(`/api/producto/${codigo}`);
			setProducto(productoResponse.data);
            		setImgSelect(productoResponse.data.imagenes[0].url)
            		setComentario({...comentario, codigo:codigo})

			// Fetch Comment
			const commentResponse = await axios.get(`/api/showusercomment/${codigo}`);
			if(commentResponse.data.id){
				setComentario(commentResponse.data)
			}

		} catch (error) {
			alert(error);
		}
	};

	fetchData();

    },[])

    const _enviarComentario = (e)=> {
        e.preventDefault();
        axios.post(`/api/comentario`, {...comentario, catalogo_id: producto.id, proveedor_id: producto.proveedor_dueno_id, pedido_id})
		.then(r=>{
            console.log('si se envio')
            setComentario(r.data)
			console.log('comentario', r)
        }).catch(e=>console.log('error', e))
    }
   
    return (
        <div className="bodyHeight bg-container">
            <Nav />
            <div className="bodyHeight">
                <div className='row m-p-0'>
					<div className='col s12 m-p-0 barra'>

						<div>  Mis Comentarios: </div>
					</div> 
				</div>
				{loader ? <Loader />:
				
                    <div className="cont-result ">
                        <div className="container-medium margin-top-50">
                            <div className='row m-p-0' style={{fontWeight:900}}>
                                <div className='col m-p-0' style={{textAlign:'right'}}>
                                    <i style={{margin:'0!important', marginTop:'-10px', padding:'0!important', paddingRight:'10px', fontWeight:900}} 
                                    className="material-icons">mood</i> 
                                </div>
                                <div className='col' style={{margin:0, marginTop:'-10px', padding:0, fontSize:'20px'}}>     
                                    {user.name} - {comentario.id}
                                </div>
                                
                                <div className='col s12' style={{fontWeight:500,margin:0, padding:0, padding:'10px 0'}}> 
                                <span>Evaluar producto: </span> <span style={{fontSize:'16px', fontWeight:900,}}> {producto.nombre} </span></div>
                            </div>
                            <div className='row m-p-0'>
                                <div className='col s12 m2 m-p-0' style={{textAlign:'center',}}>
                                    <img alt='' style={{objectFit:"scale-down", paddingTop:'0px', minHeight:'140px', maxWidth:'100%', }} src={imgSelect} />
                                </div>
                                <div className='col s11 m10' style={{padding:'0 4%'}}>
                                    
                                    {comentario.id?
                                    <div style={{ paddingBottom:'100px',}}>
                                        <div style={{backgroundColor:'#FFFFFF', borderRadius:10, minHeight:'200px',padding:'20px', display:'flex', justifyContent:'center', alignItems:'center',}}>
                                            <div className='row' style={{width:'100%'}}>
                                                <div className='col m4'>
                                                    <div className='row m-p-0' style={{display:'flex', justifyContent:'center', alignItems:'center',}}>
                                                        <img src={ReviewGif} style={{height:'150px',}} />
                                                    </div>
                                                </div>
                                                <div className='col m8' style={{padding:'2%'}}>
                                                    <p style={{fontWeight:900,fontSize:'20px'}}>Gracias por el comentario, ha sido enviado!</p>
                                                    <p>
                                                        Tu comentario esta siendo procesado, esto puede tardar. Agradecemos tu paciencia.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <form className='m-p-0' onSubmit={_enviarComentario}>
                                        <div className='col s12' style={{paddingTop:0}}> 
                                            <p className='m-p-0' style={{fontWeight:900,fontSize:'12px',}}>Calificacion general</p>

					<div style={{width:260}}>
					<div className="rating"  onChange={(e)=>setComentario({...comentario, calificacion:e.target.value})}>
						<input type="radio" id="star-5" name="rating" value="5" />
						<label htmlFor="star-5" className="label-estrella">★</label>

						<input type="radio" id="star-4" name="rating" value="4" />
						<label htmlFor="star-4" className="label-estrella">★</label>

						<input type="radio" id="star-3" name="rating" value="3" />
						<label htmlFor="star-3" className="label-estrella">★</label>

						<input type="radio" id="star-2" name="rating" value="2" />
						<label htmlFor="star-2" className="label-estrella">★</label>

						<input type="radio" id="star-1" name="rating" value="1" />
						<label htmlFor="star-1" className="label-estrella">★</label>
					</div>
					</div>


                                        </div>
                                        <div className='col s12' style={{paddingTop:0}}> 
                                            <p className='' style={{fontWeight:900, fontSize:'12px',}}>Titulo del comentario</p>
                                            <input style={{border:'solid white 1px', borderBottom:'solid 1px white', margin:0, padding:'0 5px', backgroundColor:'#ffffff', width:'100%'}} 
                                            onChange={(e)=>setComentario({...comentario, titulo:e.target.value})} />
                                        </div>

                                        <div className='col s12' style={{paddingTop:20}}> 
                                            <p className='' style={{fontWeight:900,fontSize:'12px', margin:0, padding:0, paddingTop:'15px!important',}}>Agregar un comentario</p>
                                            <textarea style={{border:'solid white 1px', borderBottom:'solid 1px white',  backgroundColor:'#ffffff', margin:0, padding:0, height:'150px', width:'100%'}} 
                                            onChange={(e)=>setComentario({...comentario, comentario:e.target.value})}
                                            rows="14"></textarea>
                                        </div>
                                        <div className='col s12' style={{paddingTop:20}}> 
                                            <button className='col s12 m2 btn waves-effect waves-light btn-footer-send primarycolor footertext' type='submit' name='action'> 
                                                Enviar
                                                <i className='material-icons right'>send</i>
                                            </button>
                                            <p style={{padding:'40px'}}></p>
                                        </div>
                                    </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default Reviews;
